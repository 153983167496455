
.eventRules
  .page-top > .bar
    margin-top: 0
    margin-right: 0
  .page-top > .search
    margin-bottom: 16px
    margin-right: 0

.eventRule-table
  .table-content
    overflow-x: auto
    scrollbar-color: var(--color-primary30) var(--background-primary)

.eventRule-row
  .btn:hover
    opacity: .7

  .table-cell:nth-child(1),
  .table-cell:nth-child(3),
  .table-cell:nth-child(5),
  .table-cell:nth-child(7)
    color: var(--text-heading2-subtitle)

  .table-cell:nth-child(3),
  .table-cell:nth-child(5),
  .table-cell:nth-child(7)
    margin-left: auto

  .table-cell:nth-child(2),
  .table-cell:nth-child(4),
  .table-cell:nth-child(6)
    padding-right: 32px
    width: calc(160px + 32px)!important

  // Edit button
  .table-cell:nth-last-child(2)
    margin-left: auto

.eventRules-tabs
  margin-bottom: 16px
  margin-top: 68px

.eventRules-tabsContainer > .layout
  padding-top: 0

.eventRules-tabsContainer
  display: flex
  flex-direction: row
  height: 100%

.evenRules-elements-name
  border: var(--color-primary) 1px solid
  border-radius: 2px
  padding: 3px 10px
  background-color: var(--message-primary)
  max-width: 100%
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  // hack to apply overflow text tooltip with paddings
  &:after
    content: ''
    display: inline-block
    width: 18px
    height: 15px

.eventRules-master-steps-container
  display: flex
  justify-content: space-around
  padding-top: 16px
  position: relative

.eventRules-master-steps
  margin: 0 -32px
  height: 100px
  background-color: var(--background-success)
  position: relative

.eventRules-master-steps-line
  height: 4px
  width: 168px
  position: absolute
  left: 9%
  top: 34%
  background-color: var(--line-general)

.eventRules-master-steps-line:nth-child(2)
  width: 109px
  left: 33.26%

.eventRules-master-steps-line:nth-child(3)
  width: 152px
  left: 53.16%

.eventRules-master-steps-line:nth-child(4)
  width: 96px
  left: 72.5%

.eventRules-master-steps-arrow
  position: absolute
  width: 20px
  height: 40px
  display: inline-block
  top: 16.8%

  &:before,
  &:after
    content: ""
    position: absolute
    background-color: var(--line-general)
    border-radius: 0.2rem
    display: block
    left: -5%
    height: 5px
    width: 100%
    transform: rotate(-45deg)

  &:before
    top: 58%
  &:after
    bottom: 58%
    transform: rotate(45deg)

.eventRules-master-steps-arrow__first
  left: 47.1%

.eventRules-master-steps-arrow__second
  left: 84.7%


.eventRules-step-icon
  display: flex
  flex-direction: column
  align-items: center

.eventRules-step-icon__zone
  left: 5.86%

.eventRules-step-icon__notification
  left: 27.29%

.eventRules-step-icon__state
  left: 49.57%

.eventRules-step-icon__event
  left: 70.14%

.eventRules-step-icon__save
  left: 89.29%

.eventRules-step-icon-holder
  display: flex
  width: 40px
  height: 40px
  background-color: var(--card-primary)
  border-radius: 50%
  transition: background-color  var(--time)
  color: var(--icon-disabled)
  box-shadow: var(--box-shadow-elevation-05)

  > svg
    margin: auto
    height: 26px
    width: 26px

.eventRules-step-icon-title
  color: var(--text-heading2-subtitle)
  font-weight: 500
  margin-top: 12px
  display: inline-block

.eventRules-master-steps__zone
  .eventRules-step-icon__zone
    .eventRules-step-icon-holder
      background-color: var(--line-primary)
      color: white

    .eventRules-step-icon-title
      color: var(--eventRule-master-steps-icon-title-active-color)

  .eventRules-master-steps-line:nth-child(1)
      background-color: var(--line-primary)

.eventRules-master-steps__notification
  .eventRules-step-icon__zone, .eventRules-step-icon__notification
    .eventRules-step-icon-holder
      background-color: var(--color-primary)
      color: white

  .eventRules-step-icon__notification
    .eventRules-step-icon-title
      color: var(--eventRule-master-steps-icon-title-active-color)

  .eventRules-master-steps-line:nth-child(1)
      background-color: var(--line-primary)

.eventRules-master-steps__state
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state
    .eventRules-step-icon-holder
      background-color: var(--color-primary)
      color: white

  .eventRules-step-icon__state
    .eventRules-step-icon-title
      color: var(--eventRule-master-steps-icon-title-active-color)

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before
    background-color: var(--line-primary)

.eventRules-master-steps__event
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state,
  .eventRules-step-icon__event
    .eventRules-step-icon-holder
      background-color: var(--color-primary)
      color: white

  .eventRules-step-icon__event
    .eventRules-step-icon-title
      color: var(--eventRule-master-steps-icon-title-active-color)

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before,
  .eventRules-master-steps-line:nth-child(3)
    background-color: var(--line-primary)

.eventRules-master-steps__save
  .eventRules-step-icon__zone,
  .eventRules-step-icon__notification,
  .eventRules-step-icon__state,
  .eventRules-step-icon__event,
  .eventRules-step-icon__save
    .eventRules-step-icon-holder
      background-color: var(--color-primary)
      color: white

  .eventRules-step-icon__save
    .eventRules-step-icon-title
      color: var(--eventRule-master-steps-icon-title-active-color)

  .eventRules-master-steps-line:nth-child(1),
  .eventRules-master-steps-line:nth-child(2),
  .eventRules-master-steps-arrow__first:after,
  .eventRules-master-steps-arrow__first:before,
  .eventRules-master-steps-line:nth-child(3),
  .eventRules-master-steps-line:nth-child(4),
  .eventRules-master-steps-arrow__second:after,
  .eventRules-master-steps-arrow__second:before
    background-color: var(--line-primary)

.eventRules-master-steps-cardActions
  display: flex

  .btn
    &:hover:before
      opacity: .1

.eventRules-master-steps-prevButton
  margin-right: auto

  .btn-content
    display: flex
    align-content: space-evenly
    justify-content: space-around
    align-items: center

    .btn-icon
      transform: rotate(180deg)
      margin-right: 0

.eventRules-master-steps-nextButton
  margin-left: auto

  .btn-content
    display: flex
    flex-direction: row-reverse
    align-content: space-evenly
    justify-content: space-around
    align-items: center

    .btn-icon
      margin-right: 0

.eventRules-master-steps-saveButton
    margin-left: auto

.eventRules-master-content-disclaimer
  margin-top: 24px

.eventRules-master-content-addButton
  margin-right: -8px
  color: var(--color-primary)
  font-size: 12px

.eventRules-master-content-bar
  padding-top: 6px
  min-height: 92px
  align-items: flex-start

  .form-field--checkbox
    margin-right: auto
    height: 36px
    display: flex
    align-items: center

.eventRules-master-content__save
  padding-top: 24px
  padding-bottom: 45px

  .form-field--checkbox
    margin-top: 18px
    height: 45px
    display: flex
    align-items: flex-end

  .col:nth-child(2) .form-field--checkbox
    margin-top: calc(18px + 45px + 18px)


@media screen and (max-width: 1350px) // LEGACY
  .eventRule-row
    .table-cell:nth-child(2),
    .table-cell:nth-child(4),
    .table-cell:nth-child(6)
      padding-right: initial

[dir = 'rtl']
  .eventRules-master
    .eventRules-master-steps-arrow__first
      right: 46%
      left: unset
      transform: scaleX(-1)

    .eventRules-master-steps-arrow__second
      left: unset
      right: 85.5%
      transform: scaleX(-1)

    .eventRules-master-steps
      .eventRules-master-steps-line
        &:nth-child(1)
          left: 68%
      .eventRules-master-steps-line
        &:nth-child(4)
          left: 12%
      .eventRules-master-steps-line
        &:nth-child(2)
          left: 52%
      .eventRules-master-steps-line
        &:nth-child(3)
          left: 25%

  .eventRules-master-steps-cardActions
    .eventRules-master-steps-nextButton, .eventRules-master-steps-saveButton
      margin-left: 0
      margin-right: auto
      .btn-content
        svg
          transform: scaleX(-1)

    .eventRules-master-steps-prevButton
      margin-left: auto
      margin-right: 0
      .btn-content
        svg
          transform: scaleX(1)

  .eventRule-table
    .eventRule-row
      .table-cell:nth-last-child(2)
        margin-left: unset
        margin-right: auto
