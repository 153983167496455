.table-row
  .ava--dynamic
    opacity: 0
    transition: opacity var(--time) var(--ease-md)

  &:hover
    .ava--dynamic
      opacity: 1

.panelInfo
  padding-bottom: 0 !important

  .card
    flex-grow: 1

  .page-card
    margin-bottom: 16px
    margin-top: 0

  .map
    margin-bottom: 16px

  .disclaimer
    margin-bottom: 16px

.panels-customer-info
  width: 400px

.pac-container
  background-color: var(--card-primary) !important

  .pac-item
    margin-top: 10px
    padding: 5px

    > :last-child
      margin-left: 5px

  .pac-item, .pac-matched, .pac-item-query
    font: var(--font-size-primary)/1.3 Roboto, sans-serif
    font-size: var(--font-size-medium)
    color: var(--text-heading1)

  .pac-icon-marker
    margin-right: 5px

  .pac-item:hover
    background-color: var(--table-hover) !important

.google-maps-message-block
  display: inline-block
  margin-bottom: 8px
  font-size: var(--font-size-small)

.google-maps-message-icon
  margin-right: 10px
  width: 10px
  color: var(--color-error)
  padding-bottom: 3px

[dir = 'rtl']
  .panelInfo
    margin-right: -12px !important

  .panels-customer-info
    position: relative

    .list-item
      padding-right: 32px
      padding-left: 0

      svg
        position: absolute
        right: 0

@media screen and (min-width: $window-x-large)
  .panelInfo
    > .scroll
      display: flex
      flex-direction: row
      align-items: flex-start
      flex-wrap: wrap

    .card
      max-width: 50%
