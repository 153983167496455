
.hidden
  display: none !important

.block
  margin-top: 32px

  > .title, > .title-group
    margin-bottom: 16px


.hr
  margin: 16px 0
  border-top: 1px solid var(--line-general)

.hint
  font-weight: 500
  font-size: var(--font-size-small)
  color: var(--color-primary)
  text-transform: uppercase

.hint--gray
  color: var(--text-heading2-subtitle)

.hint-delim
  height: 15px
  border-left: 1px solid currentColor
  width: 0
  display: inline-block
  margin: 0 8px
  vertical-align: -3px
  color: var(--text-heading1)
  opacity: var(--opacity-text-super-light)

.error
  height: 100%
  min-height: 200px
  display: flex
  margin: 0 auto
  max-width: 500px
  padding: 24px
  justify-content: center
  align-items: center
  flex-flow: column
  text-align: center

.error-title
  font-size: 24px
  font-weight: 500
  margin-bottom: 12px
  opacity: .4

.error-message:not(:last-child)
  margin-bottom: 24px

.empty-message
  text-align: center
  color: var(--text-heading2-subtitle)
  margin: 0 auto
  max-width: 400px
  padding: 24px

.title-group
  display: flex
  justify-content: space-between

.title
  font-size: var(--font-size-medium)
  font-weight: 500

.title--medium
  font-size: 16px

.title--large
  font-size: var(--font-size-large)

  .definition-detail
    font-size: var(--font-size-medium)

.title--margin
  margin: 16px 0

.title--big
  font-size: var(--font-size-big)

  .definition-detail
    font-size: var(--font-size-medium)
    margin-left: 20px

.title--secondary
  color: var(--text-heading2-subtitle)

.title--cols
  margin: 64px 0 12px
  font-size: var(--font-size-medium)

  + .cols > .form-section
    margin-top: 0

.title-hint
  margin-left: 12px
  font-weight: normal
  font-size: var(--font-size-small)
  color: var(--text-heading2-subtitle)
  display: inline

.primary
  color: var(--color-primary)

.colons
  .colon
    .group-title
      display: inline-flex

  .colon:not(:last-child):after
    content: ', '

.simple-list
  padding-left: 24px
  margin: 8px 0

ol.simple-list
  list-style-type: decimal

ul.simple-list
  list-style-type: disc
