$grid-mesh-seed: 8px

.action-cards-group
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-content: flex-start
  justify-content: flex-start
  align-items: stretch
  gap: calc($grid-mesh-seed * 2)

  > label
    font-size: var(--font-size-medium)
    font-weight: 500

.action-cards-container
  display: flex
  flex-direction: column
  flex-wrap: nowrap
  align-content: flex-start
  justify-content: flex-start
  align-items: flex-start
  gap: $grid-mesh-seed

  > label
    font-weight: 500
    font-size: var(--font-size-small)
    color: var(--color-primary)
    text-transform: uppercase

  .action-cards
    width: 100%
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-content: flex-start
    justify-content: flex-start
    align-items: flex-start
    gap: $grid-mesh-seed

    .action-card
      background: var(--button-light)
      border-radius: 2px
      padding: $grid-mesh-seed
      flex-basis: calc((100% - $grid-mesh-seed) / 2)
      flex-grow: 1
      display: flex
      flex-direction: column
      flex-wrap: nowrap
      align-content: flex-start
      align-items: stretch
      justify-content: flex-start
      gap: $grid-mesh-seed

      > label
        font-size: var(--font-size-primary)
        line-height: var(--font-size-large)

      .action-card-buttons
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        align-content: flex-start
        justify-content: space-between
        align-items: center
        gap: $grid-mesh-seed

        > button
          flex-grow: 1